import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

const Header = ({toggleModal}) => {
  const data = useStaticQuery(graphql`
  query HeaderImagesQuery {
    file(relativePath: {eq:"images/logo_transparent.png"}){
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED
          placeholder: TRACED_SVG
        )
      }
    }
  }
  `)

  return (
    <header className="fixed bg-sky-50 flex flex-wrap w-full z-30 justify-between md:py-5 py-3 px-3 md:px-10">  
      <div className="w-20 sm:w-28">
        <GatsbyImage image={data.file.childImageSharp.gatsbyImageData} alt="Logo Webique" />
      </div>
      <div className="flex text-right text-gray-700">
        <a href="https://t.me/webique" target="blank" className="sm:w-20 w-12 px-1 sm:px-0">
          <svg fill="#333" xmlns="http://www.w3.org/2000/svg" className="w-full -mt-1 sm:mt-0" viewBox="0 0 48 48" width="48px" height="48px"><path d="M5.83,23.616c12.568-5.529,28.832-12.27,31.077-13.203c5.889-2.442,7.696-1.974,6.795,3.434 c-0.647,3.887-2.514,16.756-4.002,24.766c-0.883,4.75-2.864,5.313-5.979,3.258c-1.498-0.989-9.059-5.989-10.7-7.163 c-1.498-1.07-3.564-2.357-0.973-4.892c0.922-0.903,6.966-6.674,11.675-11.166c0.617-0.59-0.158-1.559-0.87-1.086 c-6.347,4.209-15.147,10.051-16.267,10.812c-1.692,1.149-3.317,1.676-6.234,0.838c-2.204-0.633-4.357-1.388-5.195-1.676 C1.93,26.43,2.696,24.995,5.83,23.616z"/></svg>
        </a>
        <div className="flex flex-col ml-1 sm:mr-5">
          <a href="tel:89150001490" className="font-bold text-md sm:text-2xl" target="blank">+7 915 000 1490</a>
          <a href="mailto:contact@webique.ru" className="text-sm">contact@webique.ru</a>
        </div>
        <button className="hidden sm:block cursor-pointer rounded-md bg-yellow-400 w-52 text-center py-3 px-4 font-medium self-center" onClick={() => toggleModal(true)}>Перезвоните мне</button>
      </div>
    </header>
  )
}

export default Header
