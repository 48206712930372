import React from 'react';
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

const HeroScreen = ({toggleModal}) => {

  const data = useStaticQuery(graphql`
  query HeroScreenImagesQuery {
    file(relativePath: {eq:"images/rocket.png"}){
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED
          placeholder: TRACED_SVG
          )
      }
    }
  }
  `)

  return (
    <div className="container mx-auto">
      <section className="flex flex-wrap max-w-7xl mx-auto">
        <div className="md:w-2/5 px-5 md:px-0 pt-24 md:pt-0 h-96 md:h-screen flex flex-col justify-center">
          <p className="mt-25 md:mt-0 text-xs text-gray-500 font-medium uppercase whitespace-nowrap">
            Ускоряем сайты с 2015
          </p>
          <p className="text-4xl lg:text-6xl mt-3 mb-8 fs-2 text-gray-800 font-bold whitespace-nowrap">
          Сайт долго грузится?
            <br />
            Исправим!
          </p>
          <button className="cursor-pointer rounded-md bg-yellow-400 w-52 text-center py-3 px-4 font-medium" onClick={() => toggleModal(true)}>
            За работу!
          </button>
        </div>
        <div className="md:w-3/5 md:pl-20 flex flex-col justify-center pb-20">
          <GatsbyImage image={data.file.childImageSharp.gatsbyImageData} className="z-40 w-1/2 mx-auto" alt="rocket - Colorful psd created by freepik - www.freepik.com" />
        </div>
      </section>
    </div>
  )
}

export default HeroScreen
