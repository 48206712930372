import React, { useState } from "react"
// import { Link, graphql } from "gatsby"

import HeroScreen from "../components/heroscreen"
import ContentScreen from "../components/contentscreen"
import Header from "../components/header"
import Footer from "../components/footer"
import Modal from "../components/modal"
import Portfolio from "../components/portfolio"

export default function Home() {

const [modal, toggleModal] = useState(false);

  return(
    <React.Fragment>
      <Header toggleModal={toggleModal}/>
      <main className="bg-cyan-100">
        <HeroScreen toggleModal={toggleModal}/>
        <ContentScreen />
        {/* <Portfolio /> */}
      </main>
      <Footer/>
      {modal && <Modal toggleModal={toggleModal} />}
    </React.Fragment>
  )
}