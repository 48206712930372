import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Point from "./micro/point"

const ContentScreen = () => {

  const data = useStaticQuery(graphql`
  query {
    bullets: dbJson {
      bullets {
        text
        title
        img
      }
    }
  }`);

  return (
    <div className="container mx-auto">
      <section className="flex flex-wrap max-w-7xl mx-auto">
        <div className="md:w-2/5 px-5 md:px-0 pt-24 md:pt-0 h-96 flex flex-col">
          <p className="text-3xl lg:text-4xl mb-5 fs-2 text-gray-800 font-bold whitespace-nowrap">
            Отправим Pagespeed Insights<br/><span className="text-emerald-800">в зеленую зону</span>
          </p>
          <p>
            <a className="text-medium text-medium lg:text-xl mb-28 fs-2 text-red-800 whitespace-nowrap cursor-pointer border-b-2 border-red-300"
            href="https://pagespeed.web.dev/" target="_blank">Проверить свой сайт</a>
          </p>
        </div>
        <div className="md:w-3/5 text-xl md:pl-20 flex flex-col fs-2 text-gray-800">
          <ul className="list-special">
            <li className="flex mb-3">
              <p>
                Задержка загрузки на 0,1 секунду уменьшает конверсию сайта на 7%
                (
                <a
                  className="text-red-800 hover:text-red-600"
                  target="_blank"
                  rel="nofollow"
                  href="https://blogs.akamai.com/2017/04/new-findings-the-state-of-online-retail-performance-spring-2017.html"
                >
                  исследование
                </a>
                ).
              </p>
            </li>
            <li className="flex mb-3">
              <p>
                Google говорит, что из-за негативного пользовательского опыта
                вероятность повторной покупки снижается на 60%.
              </p>
            </li>
            <li className="flex mb-3">
              <p>
                Скорость загрузки влияет на ранжирование сайта в поисковиках.
              </p>
            </li>
            <li className="flex mb-3">
              <p>
                Так же это влияет на вероятность попадания вашего сайта в
                тестовую выборку поисковой нейронной сети.
              </p>
            </li>
          </ul>
        </div>

        <div className="w-full flex">
          {Object.values(data.bullets)[0].map((el,i)=>{
            return(
              <Point point={el}/>
              )
          })}
        </div>
      </section>
    </div>
  )
}

export default ContentScreen
