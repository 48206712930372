import * as React from "react"

const Footer = () => {
  return (
    <footer className="bg-sky-300 flex flex-wrap w-full z-30 justify-between md:py-5 py-3 px-3 md:px-10">
      <div className="w-full sm:w-1/2 mb-2 sm:pt-3 text-right sm:text-left">
        Webique Digital © {new Date().getFullYear()}
      </div>
      <div className="flex ml-auto flex-col sm:mr-5">
        <div className="flex text-right text-gray-700">
          <a
            href="https://t.me/webique"
            target="blank"
            className="w-5 mr-2"
          >
            <svg
              fill="#a39cd1"
              xmlns="http://www.w3.org/2000/svg"
              className="w-full"
              viewBox="0 0 48 48"
            >
              <path d="M5.83,23.616c12.568-5.529,28.832-12.27,31.077-13.203c5.889-2.442,7.696-1.974,6.795,3.434 c-0.647,3.887-2.514,16.756-4.002,24.766c-0.883,4.75-2.864,5.313-5.979,3.258c-1.498-0.989-9.059-5.989-10.7-7.163 c-1.498-1.07-3.564-2.357-0.973-4.892c0.922-0.903,6.966-6.674,11.675-11.166c0.617-0.59-0.158-1.559-0.87-1.086 c-6.347,4.209-15.147,10.051-16.267,10.812c-1.692,1.149-3.317,1.676-6.234,0.838c-2.204-0.633-4.357-1.388-5.195-1.676 C1.93,26.43,2.696,24.995,5.83,23.616z" />
            </svg>
          </a>
          <a
            href="tel:89150001490"
            className="font-bold text-sm"
            target="blank"
          >
            +7 915 000 1490
          </a>
        </div>
        <a href="mailto:contact@webique.ru" className="text-sm">
          contact@webique.ru
        </a>
      </div>
      <div></div>
    </footer>
  )
}

export default Footer
