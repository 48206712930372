import React from "react"
import IMask from 'imask';

const Modal = ({ toggleModal }) => {

function init_mask(e){
  console.log('e: ',e.target)
  let element = e.target;
  let maskOptions = {
    mask: '+{7}(000)000-00-00'
  };
  let mask = IMask(element, maskOptions);
}

function closeModal(e, toggleModal) {
  if (e.target.getAttribute("id") === "modal_bg") {
    return toggleModal(false)
  }
}

function sendData(e) {
  e.preventDefault()
  // console.log('this is submit',e);
  const XHR = new XMLHttpRequest()

  // Bind the FormData object and the form element
  const FD = new FormData(e.target)

  // Define what happens on successful data submission
  XHR.addEventListener("load", function (event) {
    document
      .getElementById("modal")
      .querySelector(".main-form>.text-2xl").innerHTML = "Благодарим за заявку!"
    document
      .getElementById("modal")
      .querySelector(".main-form>.text-2xl+p").innerHTML =
      "Менеджер свяжется с вами в ближайшее время."
    document
      .getElementById("modal")
      .querySelector("form")
      .classList.add("hidden")
    document
      .getElementById("modal")
      .querySelector("form+div")
      .classList.add("hidden")
  })

  // Define what happens in case of error
  XHR.addEventListener("error", function (event) {
    alert("Что-то пошло не так... Попробуйте обновить страницу.")
  })

  // Set up our request
  XHR.open("POST", "https://webique.ru/api/mail/mail.php")

  // The data sent is what the user provided in the form
  XHR.send(FD)
}

  return (
    <div
      id="modal_bg"
      className="bg-black/75 w-screen top-0 h-screen fixed z-50 flex justify-center items-center"
      onClick={e => closeModal(e, toggleModal)}
    >
      <div
        id="modal"
        className="z-40 w-screen text-center w-100 sm:w-1/2 lg:w-1/2"
      >
        <div className="relative mx-auto p-3 sm:p-10 rounded-3xl bg-yellow-50 ">
          <div className="mt-7 px-0 lg:px-12 main-form">
            <p className="text-center lg:text-3xl text-2xl font-black">
              Закажите звонок
            </p>
            <p className="text-center mt-4 mb-5 px-0 lg:px-5">
              Мы свяжемся с вами в ближайшее время
            </p>

            <form
              id="modal_form"
              // action="/api/mail/mail.php"
              onSubmit={e => sendData(e)}
              method="POST"
              className="flex flex-col justify-between"
            >
              <input
                type="text"
                name="fio"
                id="fio"
                placeholder="Имя"
                className="mb-4 border border-gray-300 p-3"
              />
              <input
                type="text"
                name="phone"
                id="phone"
                placeholder="+7 999 999 99 99"
                className="mb-4 border border-gray-300 p-3"
                onFocus={e => init_mask(e)}
              />
              <input
                type="text"
                name="email"
                id="email"
                placeholder="your@e.mail"
                className="mb-7 border border-gray-300 p-3"
              />
              <button
                type="submit"
                className="cursor-pointer rounded-md bg-yellow-500 w-52 text-center mx-auto py-3 px-4 font-medium"
              >
                Отправить
              </button>
            </form>

            <div className="my-5">
              <p className="text-center text-xs">
                Нажимая "Заказать", Вы даёте Согласие на обработку Ваших
                персональных данных и принимаете Пользовательское соглашение
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Modal
