import React, { useMemo } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

const Point = ({ point }) => {
  const data = useStaticQuery(graphql`
    query {
      allFile(filter: { relativeDirectory: { eq: "images/bullets" } }) {
        edges {
          node {
            name
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH, placeholder: TRACED_SVG)
            }
          }
        }
      }
    }
  `)

  const match = useMemo(
    () => data.allFile.edges.find(({ node }) => point.img === node.name),
    [data, point.img]
  )

  if (match) {
    return (
      <div class="flex flex-col mr-10 mb-12">
        <GatsbyImage
          objectFit="contain"
          image={match.node.childImageSharp.gatsbyImageData}
          className="h-52 w-3/4 mx-auto mb-8"
          alt="Colorful psd created by freepik - www.freepik.com"
        />
        <p className="text-xl mb-3 font-bold">{point.title}</p>
        <p className="text-gray-600">{point.text}</p>
      </div>
    )
  }
}

export default Point
